// 2019-10-27 15:25:00
import React from "react";
// import { useRef } from "react";
// import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";

const SneakyAnchor = styled.a`
  text-decoration: none;
  color: hsla(0, 0%, 0%, 0.8);
`;

const Stowaway = () => {
  return (
    <Layout>
      <SEO title="Highlight"></SEO>
      <p>There's a link in the below paragraph. Click it to continue.</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean congue,
        eros vitae luctus rhoncus, odio nisi lacinia arcu, a semper massa nulla
        non est. Curabitur pharetra non{" "}
        <SneakyAnchor href="/">sapien</SneakyAnchor> id viverra. Phasellus ut ex
        magna. Nunc posuere ipsum eget imperdiet luctus. Fusce et mauris quis
        magna finibus gravida a sit amet magna. Curabitur eleifend laoreet
        lorem, vitae posuere velit vehicula id. In placerat nibh ante, quis
        suscipit justo tempus vitae.
      </p>
    </Layout>
  );
};

export default Stowaway;
